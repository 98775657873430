import { render, staticRenderFns } from "./MasterPerformance.vue?vue&type=template&id=6b04def3&scoped=true&"
import script from "./MasterPerformance.vue?vue&type=script&lang=js&"
export * from "./MasterPerformance.vue?vue&type=script&lang=js&"
import style0 from "./MasterPerformance.vue?vue&type=style&index=0&id=6b04def3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b04def3",
  null
  
)

export default component.exports