<template>
  <div>
    <!-- <vx-card actionable class="cardx" :title="`Master Performance CPA/CMA - ${generated_date}`"> -->
    <vx-card actionable class="cardx">
    <vs-row>
      <vs-col  vs-justify="center" vs-align="flex-start" vs-w="6">
      <h4 v-if="selected_course == 'CPA/CMA'">Master Performance {{selected_course}} - {{generated_date}}</h4>
      <h4 v-if="selected_course == 'CFA/FRM'">Master Performance {{selected_course}} - {{generated_date}}</h4>
      </vs-col>
        <vs-col  vs-justify="start" vs-align="flex-start" vs-w="2">
          <vs-radio
              color="dark"
              v-model="selected_course"
              vs-value="CPA/CMA"
            >
              <p style="font-size: 12px">CPA/CMA/CPA-AA</p>
            </vs-radio>
        </vs-col>
        <vs-col vs-justify="start" vs-align="flex-start" vs-w="2">
           <vs-radio
              color="dark"
              v-model="selected_course"
              vs-value="CFA/FRM"
            >
              <p style="font-size: 12px">CFA/FRM</p>
            </vs-radio>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-model="selected_month"
            :options="months"
            style="width: 400px; z-index: 1000; margin-top: 4%; margin: 10px"
            placeholder="Months"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            multiple
            v-model="selected_city_classification"
            :options="city_classification_list"
            style="width: 400px; z-index: 1000; margin-top: 4%; margin: 10px"
            placeholder="City Classification"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-model="selected_gm"
            :options="gm"
            style="width: 400px; z-index: 1000; margin-top: 4%; margin: 10px"
            placeholder="GM"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <v-select
            v-model="selected_hf_nhf"
            :options="hf_nhf"
            style="width: 400px; z-index: 1000; margin-top: 4%; margin: 10px"
            placeholder="Spoc Level"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
          style="padding-top: 2px"
        >
          <vs-button
            color="dark"
            type="filled"
            @click="refresh"
            icon="refresh"
            style="margin-right: 3px"
          ></vs-button>
        </vs-col>
      </vs-row>
      <!-- Month Table-->
      <div
        style="
          width: 100%;
          max-height: 80vh;
          height: auto;
          overflow: auto;
          margin-left: 10px;
        "
        v-if="month_table && table_data.length != 0"
      >
        <table width="700px">
          <thead>
            <tr>
              <th
                style="min-width: 75px; width: 75px"
                class="col-id-no fixed-header"
              >
                Employee Code
              </th>
              <th
                style="min-width: 100px; width: 100px"
                class="col-first-name fixed-header"
              >
                Name
              </th>
              <th
                style="min-width: 100px; width: 100px"
                v-for="(tr, index) in month_headers"
                :key="index"
              >
                <div style="display: block ruby">
                  {{ tr }}
                  <vs-button
                    color="dark"
                    type="flat"
                    icon="arrow_upward"
                    @click="sortAscending(tr)"
                    v-if="
                      tr === 'YTD' ||
                      tr === 'CMA' ||
                      tr === 'CPA' ||
                      tr === 'CPA-AA' ||
                      tr === 'CPA-US' ||
                      tr === 'City' ||
                      tr === 'FRM' ||
                      tr === 'CFA' ||
                      tr === 'Q1 Sales' ||
                      tr === 'Q2 Sales' ||
                      tr === 'Q3 Sales' ||
                      tr === 'Q4 Sales' 
                    "
                  ></vs-button>
                  <vs-button
                    color="dark"
                    type="flat"
                    icon="arrow_downward"
                    @click="sortDescending(tr)"
                    v-if="
                      tr === 'YTD' ||
                      tr === 'CMA' ||
                      tr === 'CPA' ||
                      tr === 'CPA-AA' ||
                      tr === 'CPA-US' ||
                      tr === 'City' ||
                      tr === 'FRM' ||
                      tr === 'CFA' ||
                      tr === 'Q1 Sales' ||
                      tr === 'Q2 Sales' ||
                      tr === 'Q3 Sales' ||
                      tr === 'Q4 Sales'
                    "
                  ></vs-button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tr, index) in table_data" :key="index">
              <td class="col-id-no" scope="row">{{ tr.e_code }}</td>
              <td class="col-first-name" scope="row">{{ tr.name }}</td>
              <td>{{ tr.email }}</td>
              <td>{{ tr.date_of_joining }}</td>
              <td>{{ tr.city_classification }}</td>
              <td>{{ tr.gm }}</td>
              <td>{{ tr.user_tag }}</td>
              <td>{{ tr.total_sales }}</td>
              <td v-if="selected_course == 'CPA/CMA'">{{ tr.CPA }}</td>
              <td v-if="selected_course == 'CPA/CMA'">{{ tr.CMA }}</td>
              <td v-if="selected_course == 'CPA/CMA'">{{ tr.CPA_AA }}</td>
              <td v-if="selected_course == 'CFA/FRM'">{{ tr.CFA }}</td>
              <td v-if="selected_course == 'CFA/FRM'">{{ tr.FRM }}</td>
              <td>{{ tr.total_calls }}</td>
              <td>{{ tr.calls_per_day }}</td>
              <td>{{ tr.total_connected_calls }}</td>
              <td>{{ tr.call_ut }}</td>
              <td>{{ tr.mandays }}</td>
              <td>{{ tr.ytd_sales }}</td>
              <td>{{ tr.Q1_sales }}</td>
              <td>{{ tr.Q2_sales }}</td>
              <td>{{ tr.Q3_sales }}</td>
              <td>{{ tr.Q4_sales }}</td>
              <td>{{ tr.days_from_join }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Year Table-->
      <div
        style="
          width: 100%;
          max-height: 80vh;
          height: auto;
          overflow: auto;
          margin-left: 10px;
          margin-top: 8px;
        "
        v-if="year_table && table_data.length != 0"
      >
        <table width="700px">
          <thead>
            <tr>
              <th
                style="min-width: 75px; width: 75px"
                class="col-id-no fixed-header"
              >
                Employee Code
              </th>
              <th
                style="min-width: 100px; width: 100px"
                class="col-first-name fixed-header"
              >
                Name
              </th>
              <th
                style="min-width: 100px; width: 100px"
                v-for="(tr, index) in year_headers"
                :key="index"
              >
                <div style="display: block ruby">
                  {{ tr }}
                  <vs-button
                    color="dark"
                    type="flat"
                    icon="arrow_upward"
                    @click="sortAscending(tr)"
                    v-if="
                      tr === 'YTD' ||
                      tr === 'CMA' ||
                      tr === 'CPA' ||
                      tr === 'CPA-AA' ||
                      tr === 'CPA-US' ||
                      tr === 'City' ||
                      tr === 'FRM' ||
                      tr === 'CFA' ||
                      tr === 'Q1 Sales' ||
                      tr === 'Q2 Sales' ||
                      tr === 'Q3 Sales' ||
                      tr === 'Q4 Sales'
                    "
                  ></vs-button>
                  <vs-button
                    color="dark"
                    type="flat"
                    icon="arrow_downward"
                    @click="sortDescending(tr)"
                    v-if="
                      tr === 'YTD' ||
                      tr === 'CMA' ||
                      tr === 'CPA' ||
                      tr === 'CPA-AA' ||
                      tr === 'CPA-US' ||
                      tr === 'City' ||
                      tr === 'FRM' ||
                      tr === 'CFA' ||
                      tr === 'Q1 Sales' ||
                      tr === 'Q2 Sales' ||
                      tr === 'Q3 Sales' ||
                      tr === 'Q4 Sales'
                    "
                  ></vs-button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tr, index) in table_data" :key="index">
              <td class="col-id-no" scope="row">{{ tr.e_code }}</td>
              <td class="col-first-name" scope="row">{{ tr.name }}</td>
              <td>{{ tr.email }}</td>
              <td>{{ tr.doj }}</td>
              <td>{{ tr.city_classification }}</td>
              <td>{{ tr.gm }}</td>
              <td>{{ tr.user_tag }}</td>
              <td>{{ tr.YTD }}</td>
              <td v-if="selected_course == 'CPA/CMA'">{{ tr.CPA }}</td>
              <td v-if="selected_course == 'CPA/CMA'">{{ tr.CMA }}</td>
              <td v-if="selected_course == 'CPA/CMA'">{{ tr.CPA_AA }}</td>
              <td v-if="selected_course == 'CFA/FRM'">{{ tr.CFA }}</td>
              <td v-if="selected_course == 'CFA/FRM'">{{ tr.FRM }}</td>
              <td v-if="checkPresenceOfmonth('December')">{{ tr.dec }}</td>
              <td v-if="checkPresenceOfmonth('November')">{{ tr.nov }}</td>
              <td v-if="checkPresenceOfmonth('October')">{{ tr.oct }}</td>
              <td v-if="checkPresenceOfmonth('September')">{{ tr.sep }}</td>
              <td v-if="checkPresenceOfmonth('August')">{{ tr.aug }}</td>
              <td v-if="checkPresenceOfmonth('July')">{{ tr.jul }}</td>
              <td v-if="checkPresenceOfmonth('June')">{{ tr.jun }}</td>
              <td v-if="checkPresenceOfmonth('May')">{{ tr.may }}</td>
              <td v-if="checkPresenceOfmonth('April')">{{ tr.apr }}</td>
              <td v-if="checkPresenceOfmonth('March')">{{ tr.mar }}</td>
              <td v-if="checkPresenceOfmonth('February')">{{ tr.feb }}</td>
              <td v-if="checkPresenceOfmonth('January')">{{ tr.jan }}</td>
              <td>{{ tr.total_calls }}</td>
              <td>{{ tr.calls_per_day }}</td>
              <td>{{ tr.total_unique_connected_calls }}</td>
              <td>{{ tr.total_connected_calls }}</td>
              <td>{{ tr.utilization }}%</td>
              <td>{{ tr.mandays }}</td>
              <td>{{ tr.Q1_sales }}</td>
              <td>{{ tr.Q2_sales }}</td>
              <td>{{ tr.Q3_sales }}</td>
              <td>{{ tr.Q4_sales }}</td>
              <td>{{ tr.days_from_join }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="table_data.length == 0" style="color: grey; margin: 5px">
        <center>NO DATA AVAILABLE</center>
      </div>
    </vx-card>
  </div>
</template>

<script>
import constants from "../../constants.json";
import moment from "moment";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      selected_course: "CPA/CMA",
      months_tobe_with_year: [],
      months: [],
      selected_month: "",
      city_classification_list: [],
      selected_city_classification: [],
      headers: [
        "Email",
        "Date of Joining",
        "City",
        "GM",
        "Spoc Level",
        "Total Sales",
        "CPA",
        "CMA",
        "CPA-AA",
        "CPA-US",
        "Total Calls",
        "Calls/Day",
        "Connected Calls",
        "Utilization",
        "Mandays",
        "YTD Sales",
        "Q1 Sales",
        "Q2 Sales",
        "Q3 Sales",
        "Q4 Sales",
        "Tenure"
      ],
      headers_with_cfa_frm: [
        "Email",
        "Date of Joining",
        "City",
        "GM",
        "Spoc Level",
        "Total Sales",
        "CFA",
        "FRM",
        "Total Calls",
        "Calls/Day",
        "Connected Calls",
        "Utilization",
        "Mandays",
        "YTD Sales",
        "Q1 Sales",
        "Q2 Sales",
        "Q3 Sales",
        "Q4 Sales",
        "Tenure"
      ],
      year_headers: [],
      cpa_data: [],
      cpa_year_data: [],
      hf_nhf: [],
      selected_hf_nhf: null,
      gm: [],
      selected_gm: null,
      table_data: [],
      month_table: true,
      year_table: false,
      generated_date: '',
      month_headers: [],
    };
  },
  mounted() {
    this.getMonths();
  },
  watch: {
    selected_course(val){
      if(val === "CPA/CMA"){
        this.getData(this.selected_month);
        this.selected_hf_nhf = null;
        this.selected_gm = null;
        this.selected_city_classification = [];
        this.cpa_data = [];
        this.table_data = [];
        this.month_headers = this.headers;
      }
       if(val === "CFA/FRM"){
        let CFA_data = [];
        this.month_headers = this.headers_with_cfa_frm;
        for (let i = 0; i < this.table_data.length; i++) {
          const element = this.table_data[i];
          if(element.sub_team == 'CFA'){
            CFA_data.push(element);
          }
        }
        this.table_data = [];
        this.table_data = CFA_data;
      }
      this.getMonths();
    },
    selected_month: function (val) {
      if (val != "") {
        this.getMonths();
        this.getData(val);
        this.selected_hf_nhf = null;
        this.selected_gm = null;
        this.selected_city_classification = [];
        this.cpa_data = [];
        this.table_data = [];
        if(this.selected_course === "CPA/CMA"){
          this.month_headers = this.headers;
        }
        if(this.selected_course === "CFA/FRM"){
          this.month_headers = this.headers_with_cfa_frm;
        }
      }
    },
    selected_hf_nhf: function () {
      if (this.selected_month != "") {
        this.searchData();
      }
    },
    selected_gm: function () {
      if (this.selected_month != "") {
        this.searchData();
      }
    },
    selected_city_classification: function () {
      if (this.selected_month != "") {
        this.searchData();
      }
    },
  },
  methods: {
    checkPresenceOfmonth(month){
      if(this.year_headers.includes(month)){
        return true;
      }else{
        return false;
      }
    },
    getData(month) {
      let url = "";
      let year = moment().startOf("year").format("YYYY");
      let previous_year = year -1;
      let file_name = "";
      // if (month === this.months[0]) {
      //   this.month_table = false;
      //   this.year_table = true;
      //   file_name = "YTD-" + year;
      // } else {
      //   this.month_table = true;
      //   this.year_table = false;
      //   file_name = month + "_" + year;
      // }
       if (this.months_tobe_with_year.includes(month)) {
        this.month_table = true;
        this.year_table = false;
        file_name = month + "_" + year;
      }  
      if(month == `YTD-${year}`){
        this.month_table = false;
        this.year_table = true;
        file_name = `YTD-${year}`;
      }  
      if(month == `YTD-${previous_year}`){
        this.month_table = false;
        this.year_table = true;
        file_name = `YTD-${previous_year}`;
      }
      url = `${constants.MILES_CM_BACK}getMasterPerformanceData?file_name=${file_name}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("response", response);
          this.generated_date = response.data.generated_at
          this.cpa_data = response.data.data;
          this.getSearchfieldData(this.cpa_data);
          this.table_data = this.cpa_data;
          if(this.selected_course === "CFA/FRM"){
            let CFA_data = [];
            this.month_headers = this.headers_with_cfa_frm;
            for (let i = 0; i < this.table_data.length; i++) {
              const element = this.table_data[i];
              if(element.sub_team == 'CFA'){
                CFA_data.push(element);
              }
            }
            this.table_data = [];
            this.table_data = CFA_data;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    searchData() {
      if (this.selected_month != "") {
        this.processData();
      } else {
        this.$vs.notify({
          title: "Please select month",
          color: "danger",
        });
      }
    },
    processData() {
      let data = [];
      this.table_data = [];
      if (
        this.selected_city_classification.length != 0 &&
        this.selected_gm != null &&
        this.selected_hf_nhf != null
      ) {
        data = this.cpa_data.filter(
          (obj) =>
            this.selected_city_classification.includes(
              obj.city_classification
            ) &&
            obj.gm == this.selected_gm &&
            obj.user_tag == this.selected_hf_nhf
        );
        this.table_data = data;
      } else if (
        this.selected_city_classification.length != 0 &&
        this.selected_gm == null &&
        this.selected_hf_nhf == null
      ) {
        data = this.cpa_data.filter((obj) =>
          this.selected_city_classification.includes(obj.city_classification)
        );
        this.table_data = data;
      } else if (
        this.selected_city_classification.length == 0 &&
        this.selected_gm != null &&
        this.selected_hf_nhf == null
      ) {
        data = this.cpa_data.filter((obj) => obj.gm == this.selected_gm);
        this.table_data = data;
      } else if (
        this.selected_city_classification.length == 0 &&
        this.selected_gm == null &&
        this.selected_hf_nhf != null
      ) {
        data = this.cpa_data.filter(
          (obj) => obj.user_tag == this.selected_hf_nhf
        );
        this.table_data = data;
      } else if (
        this.selected_city_classification.length != 0 &&
        this.selected_gm != null &&
        this.selected_hf_nhf == null
      ) {
        data = this.cpa_data.filter(
          (obj) =>
            this.selected_city_classification.includes(
              obj.city_classification
            ) && obj.gm == this.selected_gm
        );
        this.table_data = data;
      } else if (
        this.selected_city_classification.length == 0 &&
        this.selected_gm != null &&
        this.selected_hf_nhf != null
      ) {
        data = this.cpa_data.filter(
          (obj) =>
            obj.gm == this.selected_gm && obj.user_tag == this.selected_hf_nhf
        );
        this.table_data = data;
      } else if (
        this.selected_city_classification.length != 0 &&
        this.selected_gm == null &&
        this.selected_hf_nhf != null
      ) {
        data = this.cpa_data.filter(
          (obj) =>
            this.selected_city_classification.includes(
              obj.city_classification
            ) && obj.user_tag == this.selected_hf_nhf
        );
        this.table_data = data;
      } else {
        this.table_data = this.cpa_data;
      }
    },
    getMonths() {
      // To get months from starting of year till current month
      let year = moment().startOf("year").format("YYYY");
      let list_of_months = moment.months(year);
      let current_month = moment().get("month") + 1;
      list_of_months.length = current_month;
      this.months = list_of_months;
      this.months_tobe_with_year = this.months;
      let header_1 = [];
      // Add months in year table header
      if(this.selected_course == 'CPA/CMA'){
         header_1 = [
          "Email",
          "Date of Joining",
          "City",
          "GM",
          "Spoc Level",
          "YTD",
          "CPA",
          "CMA",
          "CPA-AA",
          "CPA-US",
        ];
      }
      if(this.selected_course == 'CFA/FRM'){
         header_1 = [
          "Email",
          "Date of Joining",
          "City",
          "GM",
          "Spoc Level",
          "YTD",
          "CFA",
          "FRM",
        ];
      }
      let header_2 = [
        "Total Calls",
        "Calls/Day",
        "Total Unique Connected Calls",
        "Connected Calls",
        "Utilization",
        "Mandays",
        "Q1 Sales",
        "Q2 Sales",
        "Q3 Sales",
        "Q4 Sales",
        "Tenure"
      ];
      if(this.selected_month == `YTD-${year}`){
        this.year_headers = header_1.concat(this.months.reverse(), header_2);
      } else {
        let current_year_months = moment.months(year+1)
        this.year_headers = header_1.concat(current_year_months.reverse(), header_2);
      }
      // Add current year and previous year in dropdown
      let previous_year =  year-1;
      this.months.unshift("YTD" + "-" + previous_year);
      this.months.unshift("YTD" + "-" + year);
    },
    getSearchfieldData(data) {
      this.gm = [...new Set(data.map((item) => item.gm))];
      this.city_classification_list = [
        ...new Set(
          data.map((item) => {
            if (item.city_classification != null)
              return item.city_classification;
          })
        ),
      ];
      this.hf_nhf = [...new Set(data.map((item) => item.user_tag))];
    },
    sortAscending(column_name) {
      if (column_name === "YTD") {
        this.table_data.sort((a, b) => {
          if (a.YTD < b.YTD) return -1;
          return a.YTD > b.YTD ? 1 : 0;
        });
      } else if (column_name === "CFA") {
        this.table_data.sort((a, b) => {
          if (a.CFA < b.CFA) return -1;
          return a.CFA > b.CFA ? 1 : 0;
        });
      } else if (column_name === "FRM") {
        this.table_data.sort((a, b) => {
          if (a.FRM < b.FRM) return -1;
          return a.FRM > b.FRM ? 1 : 0;
        });
      } else if (column_name === "CPA") {
        this.table_data.sort((a, b) => {
          if (a.CPA < b.CPA) return -1;
          return a.CPA > b.CPA ? 1 : 0;
        });
      } else if (column_name === "CMA") {
        this.table_data.sort((a, b) => {
          if (a.CMA < b.CMA) return -1;
          return a.CMA > b.CMA ? 1 : 0;
        });
      } else if (column_name === "CPA-AA") {
        this.table_data.sort((a, b) => {
          if (a.CPA_AA < b.CPA_AA) return -1;
          return a.CPA_AA > b.CPA_AA ? 1 : 0;
        });
      } else if (column_name === "City") {
        this.table_data.sort((a, b) => {
          if (a.city_classification < b.city_classification) return -1;
          return a.city_classification > b.city_classification ? 1 : 0;
        });
      } else if (column_name === "Q1 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q1_sales < b.Q1_sales) return -1;
          return a.Q1_sales > b.Q1_sales ? 1 : 0;
        });
      } else if (column_name === "Q2 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q2_sales < b.Q2_sales) return -1;
          return a.Q2_sales > b.Q2_sales ? 1 : 0;
        });
      } else if (column_name === "Q3 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q3_sales < b.Q3_sales) return -1;
          return a.Q3_sales > b.Q3_sales ? 1 : 0;
        });
      } else if (column_name === "Q4 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q4_sales < b.Q4_sales) return -1;
          return a.Q4_sales > b.Q4_sales ? 1 : 0;
        });
      }
    },
    sortDescending(column_name) {
      if (column_name === "YTD") {
        this.table_data.sort((a, b) => {
          if (a.YTD < b.YTD) return 1;
          return a.YTD > b.YTD ? -1 : 0;
        });
      } else if (column_name === "CFA") {
        this.table_data.sort((a, b) => {
          if (a.CFA < b.CFA) return 1;
          return a.CFA > b.CFA ? -1 : 0;
        });
      } else if (column_name === "FRM") {
        this.table_data.sort((a, b) => {
          if (a.FRM < b.FRM) return 1;
          return a.FRM > b.FRM ? -1 : 0;
        });
      } else if (column_name === "CPA") {
        this.table_data.sort((a, b) => {
          if (a.CPA < b.CPA) return 1;
          return a.CPA > b.CPA ? -1 : 0;
        });
      } else if (column_name === "CMA") {
        this.table_data.sort((a, b) => {
          if (a.CMA < b.CMA) return 1;
          return a.CMA > b.CMA ? -1 : 0;
        });
      } else if (column_name === "CPA-AA") {
        this.table_data.sort((a, b) => {
          if (a.CPA_AA < b.CPA_AA) return 1;
          return a.CPA_AA > b.CPA_AA ? -1 : 0;
        });
      } else if (column_name === "City") {
        this.table_data.sort((a, b) => {
          if (a.city_classification < b.city_classification) return 1;
          return a.city_classification > b.city_classification ? -1 : 0;
        });
      } else if (column_name === "Q1 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q1_sales < b.Q1_sales) return 1;
          return a.Q1_sales > b.Q1_sales ? -1 : 0;
        });
      } else if (column_name === "Q2 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q2_sales < b.Q2_sales) return 1;
          return a.Q2_sales > b.Q2_sales ? -1 : 0;
        });
      } else if (column_name === "Q3 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q3_sales < b.Q3_sales) return 1;
          return a.Q3_sales > b.Q3_sales ? -1 : 0;
        });
      } else if (column_name === "Q4 Sales") {
        this.table_data.sort((a, b) => {
          if (a.Q4_sales < b.Q4_sales) return 1;
          return a.Q4_sales > b.Q4_sales ? -1 : 0;
        });
      }
      
    },
    refresh() {
      this.selected_gm = "";
      this.selected_city_classification = "";
      this.selected_hf_nhf = "";
      this.selected_month = "";
      this.table_data = [];
      this.cpa_data = [];
    },
  },
};
</script>

<style scoped>
thead th {
  top: 0;
  position: sticky;
  z-index: 20;
  background-color: white;
  color: black;
}
.col-id-no {
  left: 0;
  position: sticky;
  background-color: white;
}
.col-first-name {
  left: 80px;
  position: sticky;
  background-color: white;
}
.fixed-header {
  z-index: 50;
}
table th,
td {
  outline: 1px solid #ccc;
}
</style>